.footer {
  display: flex;
  flex-direction: column;
}

.block1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.sva {
  font-weight: bold;
}

.item {
  font-size: 16px;
  margin-block: 10px 0;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* стандарт */
}